import { useState } from "react";



function WhyUs({vars}) {
  return (

    <section id="why-us" className="why-us">
      <div className="container">

        <div className="row">
          <div className="col-xl-4 col-lg-5" data-aos="fade-up">
            <div className="content">
              <h3>Why Choose {vars.siteName}?</h3>
              <p>
                {vars.whyChooseUs.mainBlub}
              </p>
              <div className="text-center">
                <a href="#about" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7 d-flex">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                {vars.whyChooseUs.blocks.map((block, index) => 
                  <div key={index} className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={block.delay}>
                    <div className="icon-box mt-4 mt-xl-0">
                      <i className={block.icon}></i>
                      <h4>{block.title}</h4>
                      <p>{block.description}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    )
}


function About({vars}){
  return(
    <section id="about" className="about section-bg">
      <div className="container">

        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a> */}
          </div>

          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h4 data-aos="fade-up">About us</h4>
            <h3 data-aos="fade-up">{vars.aboutCard.head}</h3>
            <p data-aos="fade-up">{vars.aboutCard.subHead}</p>

            {vars.aboutCard.blocks.map((block, index) => 
              <div key={index} className="icon-box" data-aos="fade-up" data-aos-delay={block.delay}>
                <div className="icon"><i className={block.icon}></i></div>
                <h4 className="title"><a href={block.link}>{block.title}</a></h4>
                <p className="description">{block.description}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
      <br/>
      <br/>
        <div className="row justify-content-between align-items-center">
          <div className="col-2"><img  style={{ width: '70%'  }} src="assets/img/clients/canon-logo.png" className="img-fluid" alt="Canon Medical"/></div>
          <div className="col-2"><img  style={{ width: '50%' }} src="assets/img/clients/toshiba-logo2.png" className="img-fluid" alt="Toshiba Medical"/></div>
          <div className="col-2"><img  style={{ width: '100%' }} src="assets/img/clients/experian.png" className="img-fluid" alt="Experian"/></div>
          <div className="col-2"><img  style={{ width: '50%' }} src="assets/img/clients/UCI-PH.png" className="img-fluid" alt="UC Irvine Public Health"/></div>
          <div className="col-2"><img  style={{ width: '100%' }} src="assets/img/clients/capgroup.jpeg" className="img-fluid" alt="Capital Group"/></div>
        </div>
      </div>
    </section>

  )
}



function Clients({vars}){
  return(
    <section id="clients" className="clients">
      <div className="container" data-aos="fade-up">

        <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
            <div className="swiper-slide"><img src="assets/img/clients/client-1.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-2.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-3.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-4.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-5.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-6.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-7.png" className="img-fluid" alt=""/></div>
            <div className="swiper-slide"><img src="assets/img/clients/client-8.png" className="img-fluid" alt=""/></div>
          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section>
  )
}


function Services({vars}){
  return(
    <section id="services" className="services section-bg">
      <div className="container">

        <div className="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6" data-aos="fade-up">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-briefcase"></i></div>
              <h4 className="title"><a href="">Lorem Ipsum</a></h4>
              <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-card-checklist"></i></div>
              <h4 className="title"><a href="">Dolor Sitema</a></h4>
              <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-bar-chart"></i></div>
              <h4 className="title"><a href="">Sed ut perspiciatis</a></h4>
              <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-binoculars"></i></div>
              <h4 className="title"><a href="">Magni Dolores</a></h4>
              <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-brightness-high"></i></div>
              <h4 className="title"><a href="">Nemo Enim</a></h4>
              <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-calendar4-week"></i></div>
              <h4 className="title"><a href="">Eiusmod Tempor</a></h4>
              <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
            </div>
          </div>
        </div>

      </div>
    </section>

  )
}


function Contact({vars}) {

  const ZZhandleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));

    console.log("Submitting: V7", data);

    try {
        const response = await fetch(vars.contact.googleSheetContactURL, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        });

        const result = await response.json(); // Expecting JSON response

        if (result.status === "success") {
            alert("Form submitted successfully!");
            form.reset(); // Clear the form
        } else {
            alert("Something went wrong: " + result.message);
        }
    } catch (error) {
        console.error("Error:", error);
        alert("Error submitting form.");
    }
};

const [contactFormSubmitted, setContactFormSubmitted] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);

const XXhandleSubmit = async (e) => {
  e.preventDefault();
  const form = e.target;
  const formData = new FormData(form);
  const data = {};
  formData.forEach((value, key) => (data[key] = value));

  console.log("Submitting:", data);

  try {
      // const response = await fetch("http://localhost:8010/proxy", { 
      const response = await fetch(vars.contact.googleSheetContactURL, {
          method: "POST",
          mode: "no-cors", // no-cors, *cors, same-origin -- we won't be able to read the response
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
          },
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json(); // Ensure response is JSON

      if (result.status === "success") {
          alert("Form submitted successfully!");
          form.reset(); // Clear the form
      } else {
          alert("Something went wrong: " + result.message);
      }
  } catch (error) {
      console.error("Error:", error);
      alert("Error submitting form. Check console for details.");
  }
};

const xxxxhandleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));

    console.log("Submitting:", data);

    try {
        await fetch(vars.contact.googleSheetContactURL, {
            method: "POST",
            mode: "no-cors", // We cannot read the response
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        });

        // We assume the request was successful since we can't check the response
        setContactFormSubmitted(true);
        form.reset(); // Clear the form

    } catch (error) {
        console.error("Error:", error);
        alert("Error submitting form. Check console for details.");
    }
};

const handleSubmit = async (e) => {
    //submissions are sent to google sheet in hierometa google drive
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));

    // Capture User Info
    data.userAgent = navigator.userAgent; // Browser & OS info
    data.language = navigator.language; // Preferred language
    data.screenResolution = `${window.screen.width}x${window.screen.height}`; // Screen resolution
    data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Time zone

    // Detect bots (honeypot trick)
    if (data.honeypot) {
        console.warn("Bot detected! Submission blocked.");
        setIsSubmitting(false);
        return;
    }

    // console.log("Submitting:", data);
    setIsSubmitting(true);
    try {
        await fetch(vars.contact.googleSheetContactURL, {
            method: "POST",
            mode: "no-cors", // We cannot read the response
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        });

        // We assume the request was successful since we can't check the response
        setIsSubmitting(false);
        setContactFormSubmitted(true);
        form.reset(); // Clear the form

    } catch (error) {
        console.error("Error:", error);
        alert("Error submitting form. Check console for details.");
    }
};



  return (
    <section id="contact" className="contact">
      <div className="container">

        <div className="section-title">
          <h2 data-aos="fade-up">Contact</h2>
          <p data-aos="fade-up">{vars.contact.title}</p>
        </div>


        <div className="row justify-content-center">

          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
            <div className="info-box">
              <i className="bx bx-map"></i>
              <h3>Location</h3>
              <p>Orange County, CA</p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
            <div className="info-box">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>
              <a href="mailto:info@hierometa.com" target="_blank" rel="noopener noreferrer">
                info@hierometa.com
              </a>                
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
            <div className="info-box">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>+1-949-441-0112</p>
            </div>
          </div>
        </div>


      
        {contactFormSubmitted ? (
                <div className="section-title">
                  <br />
                  <h2 data-aos="fade-up">Thank You!</h2>
                  <p data-aos="fade-up">{vars.contact.thankyou}</p>
                  {/* <br /> */}
                  {/* <div className="text-center" data-aos="fade-up"><button onClick={() => setContactFormSubmitted(false)}>Send Another Message</button></div> */}
                  <div className="content">

                    <div data-aos="fade-up" className="text-center">
                      <a href="#contact" className="more-btn" onClick={() => setContactFormSubmitted(false)}>Send Another Message</a>
                    </div>
                  </div>

                
                </div>

            ) : (
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="300" style={isSubmitting ? { opacity: 0.75 } : {}}>
                  <div className="col-xl-9 col-lg-12 mt-4">
                    <form className="php-email-form" onSubmit={handleSubmit} disabled={isSubmitting}>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" readOnly={isSubmitting} required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" readOnly={isSubmitting} required />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" readOnly={isSubmitting} required />
                      </div>
                      <div className="form-group mt-3">
                        <textarea className="form-control" name="message" rows="5" placeholder="Message" readOnly={isSubmitting} required></textarea>
                      </div>
                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                      </div>

                      <input type="text" name="honeypot" style={{display: 'none'}} /*defaultValue="test honeypot for bots"*/  autoComplete="off" />


                      <div className="text-center" style={isSubmitting ? { animation: "pulse 1.5s infinite", opacity: 0.1 } : {}}>
                        <button type="submit" disabled={isSubmitting}>
                        {isSubmitting === true && contactFormSubmitted === false ? ( vars.contact.sending ) : 'Send Message'}                        
                        </button>
                      </div>
                      <style>
                        {`
                          @keyframes pulse {
                            0% { opacity: 0.6; }
                            50% { opacity: 9; }
                            100% { opacity: 0.6; }
                          }
                        `}
                      </style>


                    </form>
                  </div>


                </div>
         )}
      </div>
    </section>

    )


}


function Main({vars}) {
  return (
    <main id="main">
      <WhyUs vars={vars} />
      <About vars={vars} />
      {/* <Services vars={vars} /> */}
      {/* <Clients /> */}
      <Contact vars={vars} />
    </main>
    )
}



export default Main;
